<template>
    <Page>
        <template v-slot:title>
            Документы
        </template>
        <Documents :items="documents"/>
    </Page>
</template>

<script>
import Page from '@/applications/personal-area/layouts/page/Page'
import './documents.scss'

export default {
    name: 'DocumentsPage',
    computed: {
        documents() {
            return this.$DICTIONARY.profileDocuments
        }
    },
    components: {
        Page,
        Documents: () => import('@/components/gui/documents/Documents'),
    }
}
</script>